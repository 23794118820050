<template>

<div>
        <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="E-mail"
            >
                <b-form-input
                  id="email"
                  v-model="email"
                  name="login-email"
                  :class="$v.email.$error ? 'is-invalid' : ''"
                  placeholder="naam@voorbeeld.be"
                  autofocus
                />
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Wachtwoord</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Wachtwoord vergeten?</small>
                </b-link>
              </div>
             
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :class="$v.password.$error ? 'is-invalid' : ''"
                    name="login-password"
                    placeholder="Wachtwoord"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="login"
              :disabled="loading"
            >
              <b-spinner v-if="loading" small></b-spinner>
              Aanmelden
            </b-button>
          </b-form>
        <b-card-text class="text-center mt-2">
          <span>Nieuw bij Tapp? </span>
          <b-link :to="{name:'auth-register', query: {
            to: this.next
          }}">
            <span>Maak een account aan</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            of
          </div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            size="sm"
            variant="dark"
            :disabled="loading"
            @click="loginWithMicrosoft"
          >
          <b-spinner v-if="loading" small></b-spinner>
            <img 
            v-else
              height="20px" 
              src="@/assets/images/pages/login/microsoft.svg">
          </b-button>
          <b-button
            size="sm"
            variant="googleblue"
            :disabled="loading"
            @click="loginWithGoogle"
          >
            <b-spinner v-if="loading" small></b-spinner>
            <img 
             v-else
              height="20px" 
              src="@/assets/images/pages/login/google.svg">
          </b-button>
          <b-button
            size="sm"
            variant="dark"
            :disabled="loading"
            @click="loginWithApple"
          >
            <b-spinner v-if="loading" small></b-spinner>
            <img 
              v-else
              height="20px" 
              src="@/assets/images/pages/login/apple.svg">
          </b-button>
        </div>
</div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  props: {nextPage: {
    required: false,
  }},
  data() {
    return {
      email: "",
      password: "",
      status: true,
      // validation rules
      required,
      loading: false,
      next: this.nextPage ?? this.$route.query.to
    };
  },
  created() {
    if ( this.$route.query && this.$route.query.email != null) {
      this.email = this.$route.query.email;
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      if (!this.$v.$invalid) {
        this.loading = true;
        const payload = {
          next: this.next,
          checkbox_remember_me: this.status,
          userDetails: {
            email: this.email,
            password: this.password,
          },
          notify: (arg) => this.$toast(arg),
          closeAnimation: () => (this.loading = false),
        };
        this.$store.dispatch("auth/loginAttempt", payload);
      } else {
        this.$v.$touch();
      }
    },
    loginWithMicrosoft() {
      this.loading = true;
      const payload = {
        next: this.next,
        notify: (arg) => this.$toast(arg),
        closeAnimation: () => (this.loading = false),
      };
      this.$store.dispatch("auth/loginMicrosoft", payload);
    },
    loginWithGoogle() {
      this.loading = true;
      const payload = {
        next: this.next,
        notify: (arg) => this.$toast(arg),
        closeAnimation: () => (this.loading = false),
      };
      this.$store.dispatch("auth/loginGoogle", payload);
    },
    loginWithApple() {
      this.loading = true;
      const payload = {
        next: this.next,
        notify: (arg) => this.$toast(arg),
        closeAnimation: () => (this.loading = false),
      };
      this.$store.dispatch("auth/loginApple", payload);
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
